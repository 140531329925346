























































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Form, Pagination } from 'element-ui';
import { cardBatchModule } from '@/store';
import PageLayout from '@/components/page/layout.vue';
import CommonTabs from '@/components/common/tabs.vue';
import WatchListOption from '@/components/watch-lists/option.vue';
import BatchItem from '@/components/cards/batch-uploader/batch-item.vue';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import {
  CardType,
  CardTypesToObjects,
  IBatchUploadItem,
  IFileItem
} from "@/store/cards/cards";

let baseRules = {
  watch_lists: [
    {
      required: true,
      type: 'array',
      min: 1,
      message: 'error.required.field',
      trigger: 'change'
    }
  ]
};

const area = (bbox) => (bbox.bottom - bbox.top) * (bbox.right - bbox.left);
const biggest = (objects) => objects.reduce((acc, el) => (area(el.bbox) > area(acc.bbox) ? el : acc));

@Component({
  name: 'batch-uploader',
  components: {
    CommonTabs,
    PageLayout,
    WatchListOption,
    BatchItem,
    ObjectThumbnail
  }
})
export default class CardBatchUploader extends Vue {
  @Ref('inputDir')
  readonly inputDirRef!: HTMLInputElement;
  @Ref('inputFiles')
  readonly inputFilesRef!: HTMLInputElement;
  @Ref('form')
  readonly formRef!: Form;
  @Ref('pagination')
  readonly paginationRef!: Pagination;

  objects = 'faces';
  prefix_name = '';
  batch_upload = {} as IBatchUploadItem;
  mf_selector = 'reject';
  upload_position = -1;
  parallel_requests = 5;
  parallel_requests_items = [2, 5, 10, 20];
  currentPage = 1;
  file_name = true;
  postfix_name = '';
  file_comment = false;
  prefix_comment = '';
  postfix_comment = '';
  item = {
    watch_lists: [] as number[]
  };
  items: IFileItem[] = [];
  progress = 0;
  uploaded = 0;
  failed = 0;
  uploading = false;
  rules = this.$applyRuleMessages(baseRules);

  @Watch('cardType')
  updateBatchPage(prev) {
    if (!prev || !Object.values(CardType).includes(prev)) {
      this.$router.replace({ path: `/cards/batch/${CardType.Human}` });
    }
    this.setDefaultObjects();
  }

  get objectTabs() {
    return cardBatchModule.objectTabs(this.cardType);
  }

  get pageLabel() {
    return `${this.$tfo('batch_cards_upload')} ${this.$tt(`${this.cardType}__after_batch_upload__`)}`;
  }

  get cardType(): CardType {
    return this.$route.params.cardType as CardType;
  }

  get getWatchLists() {
    return this.$store.getters.watchlistsInDossier
      .map((list) => ({
        value: list.id,
        label: this.$filters.shortString(list.name),
        ...list
      }))
      .filter((item) => item.value > 0);
  }

  setDefaultObjects() {
    this.objects = CardTypesToObjects[this.cardType][0];
  }

  selectFiles(e) {
    this.setItems(e.target.files);
    this.inputFilesRef.value = '';
  }

  selectFolder(e) {
    this.setItems(e.target.files);
    this.inputDirRef.value = '';
  }

  setItems(files: FileList | [], append = false) {
    this.uploaded = 0;
    this.failed = 0;
    this.progress = 0;

    const items: IFileItem[] = [...files].map((v) => {
      return {
        fileName: v.name,
        file: v,
        sourcePhoto: null,
        status: '',
        getSourcePhoto: function () {
          return this.sourcePhoto ? this.sourcePhoto : (this.sourcePhoto = URL.createObjectURL(v));
        }
      };
    });

    this.items = append ? [...this.items, ...items] : items;
  }

  startHandler(e) {
    this.formRef.validate((r) => {
      if (!r) return;
      let name = this.$store.state.users.current.name + '-' + new Date().getTime() * 1000 + Math.floor(Math.random() * 1000);
      let batchUploadPromise: Promise<IBatchUploadItem> = Object.keys(this.batch_upload).length
        ? Promise.resolve(this.batch_upload)
        : this.$store.dispatch(this.$store.state.batch_upload.Action.Create, {
            name
          });
      batchUploadPromise
        .then((v) => {
          this.batch_upload = v;
          this.uploading = true;
          this.upload_position = -1;
          let processes = new Array(this.parallel_requests).fill(0);
          return Promise.all(processes.map((v) => this.uploadNext())).then((v) => {
            this.$notify({
              type: 'success',
              title: this.$tfo('batch_cards_upload'),
              message: this.$tf('finished,,1')
            });
          });
        })
        .catch((e) => {
          this.$notify({
            duration: 0,
            message: this.$createElement('message-box', { props: { e: e } })
          });
        });
    });
  }

  stopHandler() {
    this.uploading = false;
  }

  async uploadNext() {
    this.upload_position = this.upload_position + 1;

    let items = this.items;
    const position = Math.min(this.upload_position, items.length);
    const stopped = position >= items.length || !this.uploading;
    const progress = ((position * 100) / items.length) | 0;

    this.progress = progress;

    if (stopped) {
      this.uploading = false;
      return Promise.resolve(true);
    } else {
      const currentItem = items[position];
      let detectState,
        detectedItems,
        detected,
        message,
        detectionError = null;

      try {
        detectState = await this.$store.dispatch('getDetectObjectsState', {
          file: currentItem.file,
          objects: this.objects
        });
      } catch (e) {
        detectionError = e;
      }

      detectedItems = detectState?.items || [];

      if (detectionError) {
        currentItem.status = 'error';
        message = this.$tf('detection_error');
        currentItem.error = { desc: message };
      } else if (detectedItems.length === 0) {
        message = this.$tf(`no | ${this.objects},,1 | in | file,,2`) + ` ${currentItem.fileName}`;
        currentItem.status = 'error';
        currentItem.error = { desc: this.$tf(`no_${this.objects}_on_photo`) };
      } else if (this.mf_selector === 'all' || detectedItems.length === 1) {
        detected = detectedItems;
      } else if (this.mf_selector === 'reject' && detectedItems.length > 1) {
        message = this.$tf('more_than_one_object | in | file,,2') + ` ${currentItem.fileName}`;
        currentItem.status = 'error';
        currentItem.error = { desc: this.$tf('more_than_one_object') };
      } else if (this.mf_selector === 'biggest') {
        detected = [biggest(detectedItems)];
      }

      if (detected) {
        await this.uploadItemAll(currentItem, detected)
          .then(() => this.uploaded++)
          .catch(() => this.failed++);
      } else if (message) {
        this.failed++;
        this.$notify({
          type: 'warning',
          message
        });
      }

      return this.uploadNext();
    }
  }

  getFileName(name) {
    let fileNameArray = (name || '').split('.');
    if (fileNameArray.length > 1) fileNameArray.pop();
    return fileNameArray.join('.');
  }

  getComment(file) {
    return this.prefix_comment + (this.file_comment ? this.getFileName(file.name) : '') + this.postfix_comment;
  }

  getName(file) {
    return this.prefix_name + (this.file_name ? this.getFileName(file.name) : (Math.round(Math.random() * 1e12)).toString()) + this.postfix_name;
  }

  async uploadItemAll(item, detected) {
    if (item.status === 'success' || item.status === 'card') {
      return true;
    }

    item.status = 'card';
    item.error = {};

    const promises = detected.map(async (detect, i) => {
      const card = {
        name: this.getName(item.file) + (detected.length > 1 ? `_${i}` : ''),
        comment: this.getComment(item.file),
        watch_lists: this.item.watch_lists,
        active: true,
        upload_list: this.batch_upload.id
      };

      const createdCard = await cardBatchModule.createCard({ cardType: this.cardType, data: card });

      const object = {
        card: createdCard.id,
        source_photo: item.file,
        detect,
        upload_list: card.upload_list
      };
      const formData = new FormData();

      formData.append('source_photo', object.source_photo);
      formData.append('card', object.card);
      formData.append('detect_id', object.detect.id);
      formData.append('upload_list', JSON.stringify(object.upload_list));

      return this.$store
        .dispatch('requestApi', {
          model: `objects/${this.objects}`,
          data: formData,
          method: 'post',
          timeout: 6e4
        })
        .catch(() =>
          this.$store.dispatch(this.$store.state.batch_upload.Action.Delete, {
            id: createdCard.id
          })
        );
    });
    return Promise.all(promises)
      .then((v) => {
        item.status = 'success';
        item.cards = v;
        return true;
      })
      .catch((e) => {
        item.status = 'error';
        item.error = { desc: e };
        this.$notify({
          title: this.$tf('common.error'),
          message: this.$createElement('message-box', {
            props: { e }
          })
        });
      });
  }

  cancelHandler(e) {
    this.$router.go(-1);
  }

  mounted() {
    this.setItems([]);
    this.$store.dispatch(this.$store.state.watch_lists.Action.Get);
    this.$store.state.app.fileHandler = (files) => this.setItems(files, true);
    this.setDefaultObjects();
  }

  beforeDestroy() {
    this.$store.state.app.fileHandler = null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.uploading) {
      this.$confirm(this.$tf(['common.batch_upload_leave_confirm']), this.$tf(['common.warning']), {
        confirmButtonText: this.$tf(['common.ok']),
        cancelButtonText: this.$tf(['common.cancel']),
        type: 'warning'
      }).then(() => {
        this.stopHandler();
        this.setItems([]);
        next();
      });
    } else {
      next();
    }
  }
}
